.top-menu-container {
  z-index: 100000;
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--gradient);

  .top-menu-container-inner {
    background-color: rgba(0, 0, 0, 0.39);

    .top-menu-container-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: var(--top-menu-height);
      padding: 10px;

      .top-menu-left-side {
        display: flex;
        align-items: center;
        height: 100%;

        img {
          max-width: 150px;
        }

        .top-menu {
          &-title {
            font-size: 2rem !important;
          }

          &-title,
          &-items {
            @media (max-width: 766px) {
              display: none;
            }

            display: flex;
            align-items: center;
            height: 80%;
            margin-left: 1rem;
            padding-left: 1rem;
            border-left: 1px solid var(--primary-color);
          }
        }
      }

      .top-menu-right-menu {
        display: flex;
      }
    }
  }
}