.faq-article {
  :global(.exercise-description-parsed) {
    padding: 0;
    margin: 0;

    h3,
    p {
      margin: 0;
    }
  }
}
