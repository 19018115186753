.nlp-item {
    background-color: var(--surface-color);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .keyword {
        padding: 0.25rem 0.5rem;
    }

    .usage {
        padding: 0.25rem 0.5rem;
        background-color: #19181e;
    }

    &--low {
        background-color: #bba953;

        .usage {
            background-color: #8f7f33;
        }
    }

    &--high {
        background-color: #4a9d4e;

        .usage {
            background-color: #517b53;
        }
    }
}