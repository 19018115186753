.container {
  align-self: center;
  width: 100%;

  @media screen and (max-width: 960px) {
    display: none;
  }
}

.images {
  filter: grayscale(100%);
  transition: filter 0.5s;

  &:hover {
    filter: grayscale(0%);
  }
}